/* Slider */

.slick-slider {
  display             : block;
  box-sizing          : border-box;
  position            : relative;
  -moz-user-select    : none;
  -ms-user-select     : none;
  -webkit-user-select : none;
  user-select         : none;
}

.slick-list {
  display  : block;
  position : relative;
  margin   : 0;
  padding  : 0;
  overflow : hidden;
}

.slick-list:focus {
  outline : none;
}

.slick-list.dragging {
  cursor : pointer;
  cursor : hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -moz-transform    : translate3d(0, 0, 0);
  -ms-transform     : translate3d(0, 0, 0);
  -o-transform      : translate3d(0, 0, 0);
  -webkit-transform : translate3d(0, 0, 0);
  transform         : translate3d(0, 0, 0);
}

.slick-track {
  display      : block;
  position     : relative;
  top          : 0;
  left         : 0;
  margin-right : auto;
  margin-left  : auto;
}

.slick-track:after,
.slick-track:before {
  content : '';
  display : table;
}

.slick-track:after {
  clear : both;
}

.slick-loading .slick-track {
  visibility : hidden;
}

.slick-slide {
  display    : none;
  height     : 100%;
  min-height : 1px;
  float      : left;
}

[dir='rtl'] .slick-slide {
  float : right;
}

.slick-slide img {
  display : block;
}

.slick-slide.slick-loading img {
  display : none;
}

.slick-slide.dragging img {
  pointer-events : none;
}

.slick-initialized .slick-slide {
  display : block;
}

.slick-loading .slick-slide {
  visibility : hidden;
}

.slick-vertical .slick-slide {
  display : block;
  height  : auto;
  border  : 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display : none;
}
